import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { useIntl, Link } from "gatsby-plugin-react-intl"

export const ExpertsSection = () => {
  const intl = useIntl()
  const { expertsImages } = useStaticQuery(graphql`
    query {
      expertsImages: allFile(
        filter: {
          relativeDirectory: { eq: "home" }
          name: { regex: "/-Expert/" }
        }
        sort: { fields: name, order: ASC }
      ) {
        nodes {
          id
          name
          childImageSharp {
            gatsbyImageData(
              height: 320
              placeholder: BLURRED
              quality: 90
              formats: [AUTO, WEBP]
            )
          }
        }
      }
    }
  `)

  return (
    <div
      id="experts-section"
      className="w-10/12 mx-auto bg-white text-themePrimary py-16 px-2"
    >
      <ul className="w-full flex flex-col lg:flex-row gap-3 justify-between items-start">
        <li className="w-full flex flex-col justify-center items-center">
          <GatsbyImage
            image={expertsImages?.nodes[0]?.childImageSharp?.gatsbyImageData}
            alt="Luis Pomares Attorney Long Island"
          />
          <div className="w-7/12 mx-auto thumbnail-caption text-center p-3 bg-themePrimary bg-opacity-90 text-white -mt-8 z-10">
            <Link
              className="font-PTSerif font-semibold text-white text-base"
              to="/team_member/luis-pomares/"
            >
              Luis Pomares
            </Link>
          </div>
        </li>
        <li className="w-full flex flex-col justify-center items-center">
          <GatsbyImage
            image={expertsImages?.nodes[1]?.childImageSharp?.gatsbyImageData}
            alt="Percy Pomares Attorney Long Island"
          />
          <div className="w-7/12 mx-auto thumbnail-caption text-center p-3 bg-themePrimary bg-opacity-90 text-white -mt-8 z-10">
            <Link
              className="font-PTSerif font-semibold text-white text-base"
              to="/team_member/percy-pomares/"
            >
              Percy Pomares
            </Link>
          </div>
        </li>
        <li className="w-full flex flex-col justify-center items-center">
          <GatsbyImage
            image={expertsImages?.nodes[2]?.childImageSharp?.gatsbyImageData}
            alt="Michael Manfredi Attorney Long Island"
          />
          <div className="w-7/12 mx-auto thumbnail-caption text-center p-3 bg-themePrimary bg-opacity-90 text-white -mt-8 z-10">
            <Link
              className="font-PTSerif font-semibold text-white text-base"
              to="/team_member/michael-manfredi/"
            >
              Michael Manfredi
            </Link>
          </div>
        </li>
        <li className="w-full flex flex-col justify-center items-center">
          <h2 className="text-5xl font-PTSerif font-black tracking-wide text-center pb-3">
            {intl.formatMessage({ id: "Experts" })}
          </h2>
          <h3 className="text-lg font-PTSerif italic text-center pb-5">
            {intl.formatMessage({ id: "ExpertsSubTitle" })}
          </h3>
          <p className="text-center pb-5">
            {intl.formatMessage({ id: "ExpertsPara" })}
          </p>
          <Link
            to="/our-team/"
            className="moving-left font-PTSerif font-bold text-base text-center hover:text-themeAccent transition delay-150 duration-300 ease-in-out flex justify-center items-center"
          >
            {intl.formatMessage({
              id: "Read more",
            })}
            <span className="text-themeAccent pl-3">&#10095;</span>
          </Link>
        </li>
      </ul>
    </div>
  )
}

export default ExpertsSection
